<template>
    <modal
    ref="modalCodigoVerificacionLogin"
    :icon="estadoValidacion == 1 ? 'cellphone' : 'mail'"
    titulo=""
    :no-cancelar="true"
    :no-aceptar="true"
    :btns="buttons"
    @accion="confirmCode"
    >
        <div class="row mx-0 justify-center">
            <div class="col-auto" />
            <div class="col">
                <div class="row mx-0 justify-center">
                    <div class="row mx-0 my-2 text-center text-general2 f-600 f-17">
                        Ingresa el código de verificación enviado a tu {{ estadoValidacion == 1 ? 'número de celular' : 'correo electrónico' }}
                    </div>
                </div>
                <div class="row mx-0 my-4">
                    <div class="col-2 px-0 mx-1">
                        <el-input
                        ref="cod1"
                        v-model.number="cod1"
                        maxlength="1"
                        minlength="1"
                        class="w-100"
                        @input.self="next(2)"
                        />
                    </div>
                    <div class="col-2 px-0 mx-1">
                        <el-input
                        ref="cod2"
                        v-model.number="cod2"
                        maxlength="1"
                        minlength="1"
                        class="w-100"
                        @input.self="next(3)"
                        @keyup.native.delete="back(1)"
                        />
                    </div>
                    <div class="col-2 px-0 mx-1">
                        <el-input
                        ref="cod3"
                        v-model.number="cod3"
                        maxlength="1"
                        minlength="1"
                        class="w-100"
                        @input.self="next(4)"
                        @keyup.native.delete="back(2)"
                        />
                    </div>
                    <div class="col-2 px-0 mx-1">
                        <el-input
                        ref="cod4"
                        v-model.number="cod4"
                        maxlength="1"
                        minlength="1"
                        class="w-100"
                        @input.self="next(5)"
                        @keyup.native.delete="back(3)"
                        />
                    </div>
                    <div class="col-2 px-0 mx-1">
                        <el-input
                        ref="cod5"
                        v-model.number="cod5"
                        maxlength="1"
                        minlength="1"
                        class="w-100"
                        @keyup.native.delete="back(4)"
                        />
                    </div>
                </div>
                <div class="row mx-0 justify-center mb-3">
                    <div class="col-12 text-general f-15">
                        Si no has recibido el código de verificación puedes solicitarlo de nuevo o utilizar otro medio de ingreso.
                    </div>
                </div>
                <div class="row justify-center ">
                    <div class="bg-light-f5 text-general2 br-10 px-3 py-2 cr-pointer my-3" @click="resendCode">
                        <template v-if="!timeOut">
                            Volver a enviar el código
                        </template>
                        <template v-else>
                            {{ segundos }} segundos
                        </template>
                    </div>
                </div>
            </div>
            <div class="col-auto" />
        </div>
    </modal>
</template>

<script>
import authService from '~/services/auth';
const segundosDefault = 90
export default {
    props: {
        estadoValidacion: {
            type: Number,
            default: 1
        }
    },
    data(){
        return{
            cod1: '',
            cod2: '',
            cod3: '',
            cod4: '',
            cod5: '',
            buttons: [
                {
                    texto: 'Confirmar Código',
                    color: 'bg-leeche text-white br-12 px-4'
                }
            ],
            celular: false,
            timer: null,
            interval: null,
            timeOut: false,
            segundos: segundosDefault
        }
    },
    computed: {
        allCode(){
            return `${this.cod1}${this.cod2}${this.cod3}${this.cod4}${this.cod5}`
        },
        codeComplete(){
            return [this.cod1,this.cod2, this.cod3, this.cod4, this.cod5].every((el) => {
                return !_.isEmpty(`${el}`.trim())
            })
        },
        idUser(){
            return this.$store.getters['register/idUser']
        }
    },
    watch: {
        estadoValidacion(newValue){
            console.log(newValue);
        }
    },
    methods: {
        toggle( celular = false){
            this.celular = celular
            this.$refs.modalCodigoVerificacionLogin.toggle();
        },
        async confirmCode(){
            try {
                if (this.codeComplete && (!this.interval && !this.timer)){
                    const obj = {
                        "id": this.idUser,
                        "codigo": this.allCode
                    }
                    await this.$store.dispatch('auth/checkCodigoTemporalLogin', obj)
                    this.redireccion()
                }
            } catch (e){
                this.error_catch(e)
            }
        },
        next(number){
            this.$nextTick(function(){
                if(this.$refs[`cod${number - 1}`].value != "" && number > 1){
                    this.$refs[`cod${number}`].focus()
                }
            })
        },
        async resendCode(){
            try {
                if (this.interval || this.timer){
                    return
                }
                this.interval = setInterval(() => {
                    this.segundos--;
                }, 1000)
                this.timer = setTimeout(() => {
                    this.timeOut = false
                    this.segundos = segundosDefault
                    clearTimeout(this.timer)
                    clearInterval(this.interval)
                    this.timer = null
                    this.interval = null
                }, segundosDefault * 1000);

                this.timeOut = true
                let params = {
                    "usuario": this.idUser
                }
                const { data } = await authService.reenviarCodigoTemporalLogin(params)

            } catch (e){
                this.error_catch(e)
            }
        },
        back(number){
            if(this.$refs[`cod${number}`].value){
                this.$nextTick(function(){
                    this.$refs[`cod${number}`].focus()
                })
            }
        },
        redireccion(){
            this.$refs.modalCodigoVerificacionLogin.toggle();
            this.$router.push({ name: 'login.stop' })
        }
    }
}
</script>

<style lang="scss" scoped>
.resend-button{
    background-color: #F5F5F5;
    color: #5D5D5D;
    font-size: 18px;
    border-radius: 12px;
}
.br-12{
    border-radius: 12px !important;
}
</style>
